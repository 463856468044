import { globalActions } from 'primary-components';

import FORMULAS from 'resources/constants/Formulas.js';
import properties from 'resources/constants/properties.json';

import DataprepUtils from './DataprepUtils';
import FileUtils from './FileUtils';
import TimeSeriesUtils from './TimeSeriesUtils';

const FUNCTIONS_NAMESPACE = properties.functionsNamespace
const APPROXIMATE_WEB_LIMIT = 100_000
export default(() => {

  const insertTable = async ({ values: rawValues, address, tableName, shouldTranspose = false }) =>
    Excel.run(context => {
      const isInWebBrowser = Office.context.platform === "OfficeOnline"
      if(shouldTranspose && isInWebBrowser && rawValues?.[0]?.length * rawValues?.length > APPROXIMATE_WEB_LIMIT) {
        throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, `Provided data is too large (${rawValues?.[0]?.length * rawValues?.length} cells) and can not be transposed as it exceeds the web app transpose limit (${APPROXIMATE_WEB_LIMIT} cells). Disabled transpose and try again!`)
      }

      const worksheet = context.workbook.worksheets.getItem(getWorksheetFromAddress(address)),
            range = worksheet.getRange(address.split("!")[1]),
            rangeBelowFunction = range.getRowsBelow(1),
            rangeBelowHeader = rangeBelowFunction.getRowsBelow(1),
            values = shouldTranspose ? rawValues[0].map((_, colIndex) => rawValues.map(row => row[colIndex])) : rawValues,
            resizeRange = rangeBelowFunction.getAbsoluteResizedRange(values.length, values?.[0]?.length || 0),
            noHeadersResizeRagne = rangeBelowHeader.getAbsoluteResizedRange(values.length - 1 , values?.[0]?.length || 0),
            tables = resizeRange.getTables(false)
      tables.load("items")
      worksheet.comments.load("items")

      return context.sync().then(() => {
        const noHeadersResizeRagneFirstCell = noHeadersResizeRagne.getCell(0, 0),
              tableFirstCell = tables.items?.[0]?.getRange().getCell(0, 0)
        noHeadersResizeRagneFirstCell.load("address")
        tableFirstCell?.load("address")
        
        return context.sync().then(() => {
          const comment = worksheet.comments.getItemByCell(address)
          comment?.delete()
          let table = noHeadersResizeRagneFirstCell?.address === tableFirstCell?.address ? tables.items?.[0] : undefined
          return context
            .sync()
            .catch(err => {
              console.info("Comment does not exist")
              console.info(String(err))
            })
            .finally(() => {
              let oldRange
              if(table) {
                oldRange = table?.getRange()
                oldRange.numberFormat = "General"
                oldRange.load("values")
                table.load("columns")
              }
              return context
                .sync()
                .catch(err => {
                  console.info("Table does not exist")
                  console.info(String(err))
                })
                .finally(() => {
                  const valuesWithoutHeaders = values.slice(1)
                  noHeadersResizeRagne.numberFormat = "@"
                  if(!table) {
                    table = worksheet.tables.add(resizeRange, true)
                    table.style = null
                    table.showFilterButton = false
                    table.getHeaderRowRange().values = [values?.[0]] 
                    table.getDataBodyRange().values = valuesWithoutHeaders
                    table.showHeaders = false
                  } else {
                    if(oldRange) {
                      const newValues = oldRange.values.map(val => val.map(() => ""))
                      oldRange.values = newValues
                    }
                    const oldColumnsLength = table.columns.count
                    // newColumnsLength = values[0]?.length
                    // if (oldColumnsLength > newColumnsLength) {
                    //   for (let i = oldColumnsLength - 1; i >= newColumnsLength; i--) {
                    //     table.columns.getItemAt(i).delete()
                    //   }
                    // }
                    if(oldColumnsLength > 0) {
                      const oldHeadersRange = rangeBelowFunction.getAbsoluteResizedRange(1, oldColumnsLength || 0)
                      oldHeadersRange.values = ""
                    }
                    table.resize(noHeadersResizeRagne)
                    noHeadersResizeRagne.values = valuesWithoutHeaders
                    table.showHeaders = false
                  }
                  const headersRange = rangeBelowFunction.getAbsoluteResizedRange(1, values?.[0]?.length || 0)
                  headersRange.values = [values?.[0]]
                  return context.sync().then(() => {
                    worksheet.comments.add(address, tableName)
                    if (Office.context.requirements.isSetSupported("ExcelApi", "1.2")) {
                      resizeRange.format.autofitColumns();
                      resizeRange.format.autofitRows();
                    }
                    return context.sync()
                  })
                })
            })
        }).catch(err => {
          console.error(err)
          console.error(String(err))
          throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, err)
        })
      })
    }).catch(err => {
      console.error(err)
      console.error(String(err))
      throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, err)
    })


  const addFormulaToCell = ({ formula, address }) =>
    Excel.run(context => {
      let range;
      if(address) {
        const worksheet = address.split("!").length > 1 ? context.workbook.worksheets.getItem(getWorksheetFromAddress(address)) : context.workbook.worksheets.getActiveWorksheet()
        range = worksheet.getRange(address.split("!").length > 1 ? address.split("!")[1] : getWorksheetFromAddress(address))
      } else {
        range = context.workbook.getSelectedRange()
      }
      range.load("values")
      range.load("address")

      return context.sync().then(() => {
        if(range.values[0][0] && !address) {
          window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(globalActions.getMessageStore()).error("Value exists in a cell")
        } else {
          range.formulas = [[formula]]
        }
        return range.address
      })

    }).catch(err => {
      console.error(err)
      console.error(String(err))
      return Promise.resolve()
    })

  const refreshFormulaWithAdress = ({ address, formula, context, worksheet }) => {
    const worksheetLocal = worksheet ? worksheet : context.workbook.worksheets.getItem(getWorksheetFromAddress(address)),
          range = worksheetLocal.getRange(address.split("!")[1])
          range.load("formulas")
    return context.sync().then(() => {
      if(range.formulas[0][0]) {
        range.calculate()
      } else {
        range.formulas = [[formula]]
      }
    }).catch(e => console.error(e))
  }

  const getTable = ({ table, context, item, comments, validateTable }) => {
    const range = table.getRange(),
          firstTableCell = range.getCell(0, 0),
          headerCell = firstTableCell.getRowsAbove(1),
          cell = headerCell.getRowsAbove(1),
          cellComment = comments.getItemByCell(cell)
    cellComment.load("content")
    cell.load("address")
    cell.load("formulas")
    return context.sync()
      .then(() => {
        const cellValue = cellComment.content
        return validateTable({ item, cellValue, cell, formula: cell.formulas?.[0]?.[0] })
      })
      .catch(err => {
        console.error("Comment does not exist", err)
        return undefined
      })
  }
  const getTables = ({ worksheet, context, prevTables, ...props }) => {
    worksheet.tables.load("items")
    worksheet.comments.load("items")
    return context.sync()
      .then(() => 
        prevTables.concat(
          worksheet.tables.items.reduce((prev, table) => 
            prev.then((item = []) => getTable({ table, context, item, comments: worksheet.comments, ...props }))
          , Promise.resolve())
        )
      )
      .catch(err => {
        console.error(err)
      })
  }

  const getTablesInSpreadSheets = ({ ...props }) => 
    Excel.run(context => {
      context.workbook.worksheets.load("items")
      return context.sync()
        .then(() => {
          const worksheetsPromise = context.workbook.worksheets.items
            .reduce((prevPromise, worksheet) => 
                prevPromise
                  .then((prevTables = []) => getTables({ worksheet, context, prevTables, ...props }).then(items => items.filter(i => i !== undefined)))
                  .catch(err => {
                    console.error(err)
                  })
            , Promise.resolve())
          return worksheetsPromise
        })
        .then(dps => {
          const ps = Promise.all(dps).then(dps => {
            const flatDps = dps.flatMap(d => d)
            return flatDps;
          })
          return ps;
        })
        .catch(err => {
          console.error(err)
        })
    })

  const getSelectedRange = () => 
    Excel.run(context => {
      const range = context.workbook.getSelectedRange()
      range.load("address")

      return context.sync().then(() => {
        const addressSplit = range.address.split("!"),
              sheetName = getWorksheetFromAddress(range.address),
              cells = addressSplit[1].split(":"),
              firstCell = cells[0],
              lastCell = cells[1]

        return { sheetName, firstCell, lastCell }
      })
    })

  const refreshFormulasForEntity = async ({ worksheet, context, validateTable, getFormula }) => {
    await worksheet.tables.items.reduce((prev, tableItem) => 
      prev.then(() => 
        getTable({ table: tableItem, context, comments: worksheet.comments, validateTable, item: [] })
          .then(table => {
            if(table?.[0]) {
              return refreshFormulaWithAdress({ address: table[0].address, formula: getFormula(table[0]), context })
            }
            return Promise.resolve()
          })
    ), Promise.resolve())
  }

  const refreshAllFormulasInSheet = async ({ worksheet, context }) => {
    worksheet.tables.load("items")
    worksheet.comments.load("items")
    await context.sync().catch(e => console.error(e))
    // we need to handle here all formulas
    await refreshFormulasForEntity({ getFormula: item => `=${FORMULAS.LOAD_DATAPREP}("${item.name}")`, validateTable: DataprepUtils.validateTable, worksheet, context })
    await refreshFormulasForEntity({ getFormula: item => `=${FORMULAS.LOAD_FILE}("${item.fileName}","${item.groupName}")`, validateTable: FileUtils.validateTable, worksheet, context })
    await refreshFormulasForEntity({ getFormula: item => TimeSeriesUtils.paramsToFormula(item.parsedParameters), validateTable: TimeSeriesUtils.validateTable, worksheet, context })
  }

  const refreshAllFormulas = async ({ context }) => {
    const worksheets = context.workbook.worksheets
    worksheets.load("items")
    await context.sync().catch(e => console.error(e))
    await worksheets.items.reduce((prev, worksheet) => 
      prev.then(() => refreshAllFormulasInSheet({ worksheet, context }))
    , Promise.resolve())
  }

  const goToAddress = async address => 
    await Excel.run(context => {
      const worksheet = context.workbook.worksheets.getItem(getWorksheetFromAddress(address)),
            range = worksheet.getRange(address.split("!")[1])
      range.select()
      return context.sync()
    })

  const openTaskPaneComponent = componentName => {
    document.body.id = componentName
    Office.addin?.showAsTaskpane()
  }

  // const switchButton = async ({ tabId, groupId, buttonId, enabled = false }) => {
  //   if(Office.context.requirements.isSetSupported('RibbonApi', '1.1')) {
  //     await Office.ribbon
  //       .requestUpdate({
  //         tabs: [
  //           {
  //             id: tabId,
  //             groups: [
  //               {
  //                 id: groupId,
  //                 controls: [{ id: buttonId, enabled }],
  //               },
  //             ],
  //           },
  //         ],
  //       })
  //       .catch(err => console.error(err));
  //   }
  // }

  const getWorksheetFromAddress = address => address.split("!")[0].replace(/'/g, "")

  const isNGFormula = formula => formula?.startsWith(`=${FUNCTIONS_NAMESPACE}`) || formula?.includes(FUNCTIONS_NAMESPACE.replaceAll(".", "_"))

  const clearSelectedTable = ({ context, table }) => {
    const tableRange = table.getRange(),
          headerRange = tableRange.getRowsAbove(1),
          formulaRange = headerRange.getRowsAbove(1).getCell(0,0)
    formulaRange.load("formulas")

    return context.sync().then(() => {
      if(isNGFormula(formulaRange.formulas?.[0][0])) {
        table?.delete()
        headerRange.clear()
        formulaRange.clear()
      } else {
        window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(globalActions.getMessageStore())?.error("This table has not been created using the add-in and it cannot be cleared.")
      }
    })
  }

  const clearTableBelowFormula = ({ context, formulaRange }) => {
    const tablesBelowFormula = formulaRange.getRowsBelow(2).getTables()
          tablesBelowFormula.load("items")

    return context.sync().then(() => {
      const tableBelowFormula = tablesBelowFormula.items?.[0]

      if(tableBelowFormula) {
        const tableRange = tableBelowFormula.getRange(),
              headerRange = tableRange.getRowsAbove(1)
        tableBelowFormula?.delete()
        headerRange.clear()
      }
      formulaRange.clear()
    })
  }

  const clearTableWithFormulaAddress = address =>
    Excel.run(context => {
      const worksheet = context.workbook.worksheets.getItem(getWorksheetFromAddress(address)),
            range = worksheet.getRange(address.split("!")[1])

      return clearTableBelowFormula({ context, formulaRange: range })
    })

  const clearActiveRangeTable = () => 
    Excel.run(context => {
      const activeRange = context.workbook.getSelectedRange(),
            tablesInActiveRange = activeRange.getTables(),
            tablesBelowHeader = activeRange.getRowsBelow(1).getTables()
      tablesInActiveRange.load("items")
      activeRange.load("formulas")
      tablesBelowHeader.load("items")

      return context.sync().then(() => {
        const tableInActiveRange = tablesInActiveRange.items?.[0],
              tableBelowHeader = tablesBelowHeader.items?.[0]
        if(tableInActiveRange) {
          return clearSelectedTable({ context, table: tableInActiveRange })
        } else if(tableBelowHeader) {
          return clearSelectedTable({ context, table: tableBelowHeader })
        } else if(isNGFormula(activeRange.formulas?.[0][0])) {
          return clearTableBelowFormula({ context, formulaRange: activeRange })
        } else {
          window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(globalActions.getMessageStore())?.error("This table has not been created using the add-in and it cannot be cleared.")
        }
      })
    })
  

  return {
    insertTable,
    addFormulaToCell,
    refreshFormulaWithAdress,
    getTablesInSpreadSheets,
    refreshAllFormulasInSheet,
    refreshAllFormulas,
    goToAddress,
    openTaskPaneComponent,
    getSelectedRange,
    getWorksheetFromAddress,
    clearActiveRangeTable,
    clearTableWithFormulaAddress,
    isNGFormula
  }

})()