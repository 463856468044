import { ActionService } from 'dashboard-services';

export default (() => {
  
  const ON_CHANGE = 'ON_CHANGE_BASIC_TIME_SERIES'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')

  const CLEAR = 'CLEAR_BASIC_TIME_SERIES'
  const clear = ActionService.makeActionCreator(CLEAR)

  return {
    onChange,
    ON_CHANGE,
    CLEAR,
    clear
  }
})()