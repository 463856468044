import { memo } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import './MetadataLabel.scss';

const MetadataLabel = ({ name, isSmall }) => (
  <div
      className={classNames(
        "ng-office-app__authed__content__body__item__settings__bottom__value__metadata-option",
        { "ng-office-app__authed__content__body__item__settings__bottom__value__metadata-option--is-small" : isSmall },
      )}
  >
    <p>{name}</p>
    <p>METADATA</p>
  </div>
)

MetadataLabel.defaultProps = {
  isSmall: false
}

MetadataLabel.propTypes = {
  isSmall: PropTypes.bool,
  name: PropTypes.string.isRequired,
}

export default memo(MetadataLabel)